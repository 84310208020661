<template>
  <div class="brandNews">
    <img src="../assets/img/new-banner.jpg" />
    <van-tabs v-model:active="activeTypeIndex">
      <van-tab
        v-for="item in newsTypes"
        :key="item.name"
        :title="item.name"
      ></van-tab>
    </van-tabs>
    <div class="news-wrap">
      <div class="brand-new" v-if="newList && newList.length">
        <brandNew :newList="newList" />
      </div>
      <div class="no-data" v-else>
        <img src="../assets/img/没有数据.png" />
        <p>暂无相关资讯</p>
      </div>
    </div>
  </div>
  <appFooter />
</template>

<script>
import brandNew from "@/components/brandNew.vue";
import { getBrandNew } from "@/api/index.js";
import appFooter from "@/components/footer.vue";
import { trackEvent } from "@/utils/tracklog.js";
export default {
  components: { brandNew, appFooter },
  data() {
    return {
      activeTypeIndex: 0,
      newsTypes: [
        { name: "艺龙酒店科技", value: "0" },
        { name: "酒管公司", value: "1" },
        { name: "赋能平台", value: "2" },
      ],
      newList: [],
    };
  },
  mounted() {
    this.getNews();
    //埋点 品牌资讯
    trackEvent(`品牌资讯`, "浏览", `品牌资讯`, {}, 0, new Date().getTime());
  },
  methods: {
    getNews() {
      getBrandNew({ mobileBelong: this.activeTypeIndex }).then((res) => {
        this.newList = res.rows.map((item) => {
          return {
            ...item,
            url: item.imgFiles && item.imgFiles[0] && item.imgFiles[0].url,
          };
        });
      });
    },
  },
  watch: {
    activeTypeIndex() {
      this.getNews();
    },
  },
};
</script>

<style lang="scss" scoped>
.brandNews {
  min-height: 70vh;
}

img {
  width: 100%;
  height: 100%;
}

.news-wrap {
  display: flex;
  width: 100%;
  .brand-new {
    width: 100%;
  }

  .new-type {
    width: 140px;
    margin-right: 16px;

    li {
      width: 100%;
      box-sizing: border-box;
      padding: 10px 20px;
      box-sizing: border-box;

      &.active {
        color: #fff;
        background: #005250;
      }
    }
  }

  .no-data {
    text-align: center;
    color: #999;
    width: 100%;
    padding: 20%;
    box-sizing: border-box;

    img {
      width: 160rem;
      height: 160rem;
    }
  }
}
</style>
<style>
.brandNews .van-tabs__line {
  background-color: #005352;
}
</style>